<script setup lang="ts">
import { Typography, Button } from '@temperworks/components'
import {
  TypographyType,
  IconNames,
  IconColors,
  ButtonType,
  IconSizes,
  StatusNotificationType,
} from '@temperworks/types'
import { TemperIcon } from '@temperworks/icons'
import { computed, watch, withDefaults } from 'vue'

interface Props {
  id: string,
  open: boolean
  type: StatusNotificationType
  text: string
  buttonText?: string
  withCloseButton?: boolean,
  action?: () => void,
  closeCountDownMs?: number
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
  closeCountDownMs: 4000
})

const emit = defineEmits(['action', 'close'])

const iconProperties = computed(() => {
  let name
  let color

  switch (props.type) {
    case 'success':
      name = IconNames.bubbleCheckFilled
      color = IconColors.green600
      break
    case 'info':
      name = IconNames.loading
      color = IconColors.bright
      break
    case 'danger':
      name = IconNames.bubbleClearFilled
      color = IconColors.ferrari100
      break
    default:
      name = IconNames.bubbleCheckFilled
      color = IconColors.green600
  }

  return { name, color }
})

const spinnerClass = computed(() => {
  return props.type === 'info' ? 'spinner' : ''
})

let closeTimer: ReturnType<typeof setTimeout>;

watch(() => props.open, (isOpen) => {
  if (closeTimer) {
    clearTimeout(closeTimer)
  }

  if (isOpen && props.type === 'success') {
    closeTimer = setTimeout(() => {
      emit('close')
    }, props.closeCountDownMs)
  }
}, { immediate: true })

</script>

<template>
  <div
    v-show="open"
    :class="type"
    class="status-notification"
  >
    <div class="content">
      <div class="icon">
        <TemperIcon
          :name="iconProperties.name"
          :color="iconProperties.color"
          :size="IconSizes.large"
          :class="spinnerClass"
        />
      </div>
      <Typography
        :variant="TypographyType.bodyMedium"
        :content="text"
      />
    </div>
    <Button
      v-if="buttonText"
      :variant="ButtonType.outlineMedium"
      :label="buttonText"
      @click="emit('action')"
    />
    <div
      v-if="withCloseButton"
      class="close"
      @click="emit('close')"
    >
      <TemperIcon
        :name="IconNames.close"
        :color="IconColors.bright"
        :size="IconSizes.standard"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './StatusNotification.scss';
</style>
